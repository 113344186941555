class DateTime extends Date {
  constructor(time = Date.now()) {
    super(time);
  }
  /** Returns whether the given time is before the current time. */
  isBefore(date) {
    return this < new Date(date);
  }
  /** Returns whether the given time is after the current time. */
  isAfter(date) {
    return this > new Date(date);
  }
  /** Returns whether the given time is the same time, optionally for the specified unit (e.g., 'year', 'month', 'day', 'week'). */
  isSame(date, unit = "") {
    const thisDate = new Date(this);
    const nextDate = new Date(date);
    switch (unit) {
      case "year":
        thisDate.setMonth(0);
        nextDate.setMonth(0);
      case "month":
        thisDate.setDate(1);
        nextDate.setDate(1);
      case "day":
        thisDate.setHours(0);
        nextDate.setHours(0);
      case "hour":
        thisDate.setMinutes(0);
        nextDate.setMinutes(0);
      case "minute":
        thisDate.setSeconds(0);
        nextDate.setSeconds(0);
      case "second":
        thisDate.setMilliseconds(0);
        nextDate.setMilliseconds(0);
    }
    return thisDate.getTime() === nextDate.getTime();
  }
  /** Returns a new DateTime with the given time added. */
  add(number = 0, unit) {
    number = _getNumber(number);
    const datetime = new DateTime(this);
    if (unit === "week") {
      datetime.setDate(this.getDate() + number * 7);
    } else if (Object.hasOwn(_mapOfDateFns, unit)) {
      datetime["set" + _mapOfDateFns[unit]](
        datetime["get" + _mapOfDateFns[unit]]() + number
      );
    } else {
      datetime.setTime(this.getTime() + number);
    }
    return datetime;
  }
  getParts(locale = DateTime.locale) {
    const [format, formatParts] = _getFormatters(locale, this);
    const offset = -this.getTimezoneOffset();
    return {
      // year
      YYYY: format({ year: "numeric" }),
      YY: format({ year: "2-digit" }),
      // month
      MMMM: format({ month: "long" }),
      MMM: format({ month: "short" }),
      MM: format({ month: "2-digit" }),
      M: format({ month: "numeric" }),
      // date
      DD: format({ day: "2-digit" }),
      D: format({ day: "numeric" }),
      dddd: format({ weekday: "long" }),
      ddd: format({ weekday: "short" }),
      // hour
      HH: _padZero(formatParts({ hour: "numeric", hour12: false }).hour, 2),
      H: format({ hour: "numeric", hour12: false }),
      hh: _padZero(formatParts({ hour: "2-digit", hour12: true }).hour, 2),
      h: formatParts({ hour: "numeric", hour12: true }).hour,
      // minute
      mm: _padZero(format({ minute: "2-digit" }), 2),
      m: format({ minute: "numeric" }),
      // second
      ss: _padZero(format({ second: "2-digit" }), 2),
      s: format({ second: "numeric" }),
      // milliseconds
      SSS: _padZero(this.getMilliseconds(), 3),
      // day period
      A: formatParts({ hour: "numeric", hour12: true }).dayPeriod.toLocaleUpperCase(
        locale
      ),
      a: formatParts({ hour: "numeric", hour12: true }).dayPeriod.toLocaleLowerCase(
        locale
      ),
      // timezone
      ZZZ: formatParts({ timeZoneName: "short" }).timeZoneName,
      ZZ: `${offset >= 0 ? "+" : "-"}${_padZero(Math.abs(Math.floor(offset / 60)), 2)}${_padZero(offset % 60, 2)}`,
      Z: `${offset >= 0 ? "+" : "-"}${_padZero(Math.abs(Math.floor(offset / 60)), 2)}:${_padZero(offset % 60, 2)}`
    };
  }
  /** Formats the current DateTime using the given template. */
  format(template, locale = DateTime.locale) {
    const parts = this.getParts(locale);
    const matcher = new RegExp(["\\[([^\\]]+)]", ...Object.keys(parts)].join("|"), "g");
    return template.replace(
      matcher,
      ($0, $1) => $1 ?? parts[$0]
    );
  }
  /** Returns a relative time string describing how much time has passed since the given time. */
  from(time, locale = DateTime.locale) {
    const rtf = new Intl.RelativeTimeFormat(locale, { numeric: "auto" });
    const delta = new Date(time) - new Date(this);
    for (const [unit, ms] of _formats) {
      const value = Math.round(delta / ms);
      if (value !== 0) return rtf.format(-value, unit);
    }
    return rtf.format(-Math.floor(delta / 1e3), "second");
  }
  /** Returns a relative time string describing how much time has passed since the current time. */
  fromNow(locale = DateTime.locale) {
    return this.from(Date.now(), locale);
  }
  /** Returns a relative time string describing how much time is left until the given time. */
  to(time, locale = DateTime.locale) {
    return new DateTime(time).from(this, locale);
  }
  /** Returns a relative time string describing how much time is left until the current time. */
  toNow(locale = DateTime.locale) {
    return this.to(Date.now(), locale);
  }
  static locale = "en";
}
const _partMapper = (parts, part) => Object.assign(parts, { [part.type]: part.value });
const _getFormatters = (locale, date) => [
  (options) => new Intl.DateTimeFormat(locale, options).format(date),
  (options) => new Intl.DateTimeFormat(locale, options).formatToParts(date).reduce(_partMapper, {})
];
const _padZero = (number, maxLength = 2) => String(number).padStart(maxLength, "0");
const _getNumber = (value) => Number(value) || 0;
const _mapOfDateFns = {
  year: "FullYear",
  month: "Month",
  week: "Date",
  day: "Date",
  hour: "Hours",
  minute: "Minutes",
  second: "Seconds"
};
const _formats = [
  ["year", 31536e6],
  ["month", 2592e6],
  ["week", 6048e5],
  ["day", 864e5],
  ["hour", 36e5],
  ["minute", 6e4]
];
const index = (time = Date.now()) => new DateTime(time);

export { DateTime, index as default };
